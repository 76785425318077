export const SET_CANVAS_BG = "SET_CANVAS_BG"
export const SET_CANVAS_BG_TYPE = "SET_CANVAS_BG_TYPE"
export const SET_CANVAS_ASPECT = "SET_CANVAS_ASPECT"
export const SET_NAV_PANEL = "SET_NAV_PANEL"
export const SET_SELECTED_IMAGES = "SET_SELECTED_IMAGES"
export const SET_CANVAS_SCALE = "SET_CANVAS_SCALE"
export const SET_POS_X = "SET_POS_X"
export const SET_POS_Y = "SET_POS_Y"
export const SET_ROT_X = "SET_ROT_X"
export const SET_ROT_Y = "SET_ROT_Y"
export const SET_ROT_Z = "SET_ROT_Z"
export const SET_MOVEABLE_TARGET = "SET_MOVEABLE_TARGET"
export const ADD_MOVEABLE_STUFFS = "ADD_MOVEABLE_STUFFS"
export const SET_MOVEABLE_STUFFS = "SET_MOVEABLE_STUFFS"
export const SET_TARGET_TYPE = "SET_TARGET_TYPE"
export const RESET_MOVEABLE_TARGET = "RESET_MOVEABLE_TARGET"